<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper mt-5">
    <b-link class="brand-logo">
      <div
        v-if="pageData"
        class="row"
      >
        <div

          :class="
            pageData.slug.toLowerCase() === 'ois'
              ? 'col-md-3 col-sm-12'
              : 'col-md-2 col-sm-12'
          "
        >
          <b-img
            fluid
            :src="pageData.favicon || pageData.logo"
            alt="Fems"
            :style="{ height: '50px' }"
          />
        </div>
        <div
          :class="
            (pageData.slug.toLowerCase() === 'ois' ? 'col-md-9' : 'col-md-10') +
              ' col-sm-12'
          "
        >
          <h2
            class="font-weight-bold font-size-sm font-size-md mb-1"
            :style="{
              lineHeight: pageData.slug.toLowerCase() === 'ois' ? '50px' : '',
            }"
          >
            {{ pageData.name || "Fingerprint Education Management System" }}
          </h2>
        </div>
      </div>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Connection Error 🛠
        </h2>

        <p
          class="mb-3 text-left"
        >
          <b>Could you please try logging out and log back in again.</b>
          <br>
          <ul>
            <li><span> You can also try in incognito/private mode.</span> Short video on how to use Chrome's Incognito mode: <b-link
              target="_black"
              href="https://youtu.be/bu5b_jYWVcQ"
            >
              Click Here
            </b-link>
            </li>
            <li> <span>If that doesn't work, please clear the cache memory of your browser and then try again.</span> Short video on how to clear cache: <b-link
              target="_black"
              href="https://youtu.be/RUsouNnVqww&t=17"
            >
              Click Here
            </b-link>
            </li>
          </ul>

          <b>Note : </b> That clearing the cache may log you out of the sites that you are currently logged in to. Let me know if the issue persists.
        </p>
        <b-img
          fluid
          :src="downImg"
          alt="Under maintenance page"
        />

      </div>

      <!-- <p class="text-center">
        <b-button
          :disabled="isLoaddingPreloader"
          variant="primary"
          class="mb-1 btn-sm-block text-center"
          @click="onRefresh"
        >
          Refresh
        </b-button>
      </p> -->
    </div>
  </div>
<!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
//   BButton,
} from 'bootstrap-vue'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import AuthService from '@/@service/api/Auth.service'

export default {
  components: {
    BLink,
    BImg,
    // BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/conection-error.png'),
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: '',
      isLoaddingPreloader: false,
    }
  },
  computed: {

  },
  created() {
    if (this.getPageData) {
      this.isLoaddingPreloader = false
      this.pageData = this.getPageData
    }
  },
  methods: {
    onRefresh() {
      const accessToken = LocalStorageService.get('accessToken')

      this.isLoaddingPreloader = true
      AuthService.userInfo().then(
        resp => {
          const response = resp.data
          if (response.status) {
            this.isLoaddingPreloader = false
            window.location = '/'
          }
        },
      ).catch(() => {
        this.isLoaddingPreloader = false
      }).catch(() => {
        console.log(accessToken)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
